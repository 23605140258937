// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

require.context('../images', true)

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import '../stylesheets/application'
import './bootstrap_custom.js'
import 'virtual-keyboard/dist/js/jquery.keyboard'

$(function() {
  // Alerts
  $(".alert:not(.static)").delay(4000).slideUp(500, function(){
    $(".alert").alert('close');
  });

  // // Radio button - styled checked default
  // if ( $($('span.radio label input:checked')[0].parentElement).length ){
  //   $($('span.radio label input:checked')[0].parentElement).addClass('checked');
  // }

});


document.onclick = function(e) { 
  var element = e.target;

  // Keyboard position
  if (element.localName == 'input') {
      var element_position = element.getBoundingClientRect();

      var keyboard_left = parseInt(element_position.left + (element_position.width / 2));
      var keyboard_top = element_position.top;
      var keyboard_id = '#' + element.id + '_keyboard';

      var keyboard =  $(keyboard_id);
      keyboard.css("left", keyboard_left);
      keyboard.css("top", keyboard_top);
      keyboard.css('opacity', '1');
  }

  // Radio buttons - toggle .focused and .checked class
  var parent = element.parentElement;
  if (parent.localName != null && parent.localName == 'span' && parent.className == 'radio'){
      $('span.radio label').removeClass('focused').removeClass('checked');
      $(element).removeClass('focused').addClass('checked');
  }
};


// TO DO: Radio buttons hover
// $( "span.radio label" )
//   .mouseenter(function() {
//     console.log('mouseenter');
//   })
//   .mouseleave(function() {
//     console.log('mouseleave');
//   });